import Login from "../src/pages/Login";
import Signup from "../src/pages/Signup";
import PswForget from "../src/pages/PswForget";
import Layout9 from "../src/pages/Layout9/Layout9";

const routes = [
  { path: "/password-forget", component: PswForget },
  { path: "/sign-up", component: Signup },
  { path: "/login", component: Login },
  { path: "/", component: Layout9 },
];

export default routes;
