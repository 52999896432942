import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Import FeatherIcon
import FeatherIcon from "feather-icons-react";

// Import Feature Image
import Background from "../assets/images/demos.png";
import features_img1 from "../assets/images/features-1.jpg";
import dot_img from "../assets/images/dot-img.png";
import features_img2 from "../assets/images/features-2.jpg";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="About">
          <Container>
            <Row className="justify-content-center mb-5">
              <Col lg={7} className="text-center">
                <h2 className="fw-bold">About Us</h2>
                <p className="text-muted">
                Delivering quality-assured industrial products and tailored solutions to meet diverse client needs.
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mb-5">
              <Col md={5} className="order-2 order-md-1 mt-md-0 mt-5">
                <h2 className="mb-4">Trusted Partner for Industrial Products and Solutions</h2>
                <p className="text-muted mb-5">
                At Millennium Engineers Incorporation, we pride ourselves on being a trusted partner for industrial products and solutions. 
                With our extensive product portfolio and deep industry knowledge, we strive to meet the diverse requirements of our clients across various sectors.
                </p>
                <Link to="#" className="btn btn-primary">
                  Find out more{" "}
                  <i>
                    <FeatherIcon icon="arrow-right" className="icon-xs ms-2" />
                  </i>
                </Link>
              </Col>
              <Col md={6} className="ms-md-auto order-1 order-md-2">
                <div className="position-relative">
                  <div className="ms-5 features-img">
                    <img
                      src={features_img1}
                      alt=""
                      className="img-fluid d-block mx-auto rounded shadow"
                    />
                  </div>
                  <img src={dot_img} alt="" className="dot-img-left" />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center section pb-0">
              <Col md={6}>
                <div className="position-relative mb-md-0 mb-5">
                  <div className="me-5 features-img">
                    <img
                      src={features_img2}
                      alt=""
                      className="img-fluid d-block mx-auto rounded shadow"
                    />
                  </div>
                  <img src={dot_img} alt="" className="dot-img-right" />
                </div>
              </Col>
              <Col md={5} className="ms-md-auto">
                <h2 className="mb-4">
                Commitment to Quality and Excellence
                </h2>
                <p className="text-muted mb-5">
                Quality is our utmost priority. We have implemented stringent quality control measures and adhere to international standards to ensure that our products and services meet the highest levels of quality and reliability. Our commitment to excellence has earned us a reputation for being a trusted and reliable partner in the industry.
                </p>
                <Link to="#" className="btn btn-primary">
                  Find out more{" "}
                  <i>
                    <FeatherIcon icon="arrow-right" className="icon-xs ms-2" />
                  </i>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
